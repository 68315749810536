<template>
  <div>Copyright © 王海涛19052164.</div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
